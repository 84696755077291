@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');


body {

    margin: 0;
}


.App {

    text-align: center;
}
body {
    font-family: sans-serif;
    margin: 0;
    background: url("../src/assets/images/2022-10-14 01.18.30.jpg") ;
}
.wrap {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
}
.profile {
    text-align: center;
    color: #FFF;
}
.photo {
    border-radius: 50%;
}
.profile_name {
    font-weight: bold;
    display: block;
}
.at {
    font-size: 13px;
}
.links ul {
    list-style: none;
}
.links ul li {
    margin: 14px 0;
}
.link {
    display: block;
    padding: 22px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    transition: ease all 0.3s;
    color: #FFF;

}

.link p{
    font-size: 22px;
    padding: 0;
    margin: 0;
}



span {
    color: rgba(2, 14, 12, 0.81);
    font-weight: 600;
}
.itemsCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemsInBox {
    display: flex;
    justify-content: space-around;
}

.logo img{
    border-radius: 50%;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 42px;
}



ul {
    padding: 0;

}
li {
    background: #ffc3c0;
    border-radius: 10px;

}

.itemColor {
    background-color: #ffbc00;
    color: #fff;
}

.logo img{
    width: 35%;
    height: 35%;
}

.text {
    text-align: center;
    line-height: 1.4;
    font-size: 1.26rem;
    --theme-font-weight: 400;
    --theme-font-weight-bold: 700;
    color: #FFF;
}

.text p {
    margin: 0;
}

.linkLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-bottom: -26px;

}

.black {
    background-color: black;
    opacity: 0.7;
}

.bBlack {
    background-color: black;
    opacity: 0.7;
}

.block {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
}

.item {
    padding: 0 5px;
}

.item img{
    width: 200px;
    border-radius: 5px;


}

.hr {
    width: 80px;
    margin-left: 170px;
    align-items: center;
    text-align: center;
    color: black;

    height: 1px;
    background-color: #0c0707;
    border: none;
}

hr {
    height: 1px;
    background-color: #0c0a0a;
    border: none;
}

h1 {
    color: #fff;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-family: 'Anton', sans-serif;

}

.footer {
    background-color: #0c0a0a;
    padding: 10px;
    margin: 0;
    opacity: 0.9;
}

.footer p{
    color: #fff;
    font-weight: 600;
}










